import React from "react";
import type { LayerConfig } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import LegendToggleButton from "./LegendToggleButton";
import MenuCheckbox from "./MenuCheckbox";
import SettingsMenuSection from "./SettingsMenuSection";

interface LayerCheckboxProps {
  isVisible: boolean;
  layer: LayerConfig;
}

const LayerCheckbox = ({ isVisible, layer }: LayerCheckboxProps) => {
  const {
    activateLayer,
    deactivateLayer,
    getLayerState,
    isLayerActive,
    setLayerState,
  } = useActiveLayersContext();

  const state = getLayerState(layer.id);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      activateLayer({ id: layer.id, source: "layers-drawer" });
    } else {
      deactivateLayer({ id: layer.id });
    }
  };

  const onClose = () => {
    if (isLayerActive(layer.id)) {
      setLayerState({
        id: layer.id,
        source: "layers-drawer",
        state: { opacity: 1 },
      });
    }
  };

  let hint: React.ReactNode = null;
  if (layer.hintComponent) {
    const { hintComponent: HintComponent } = layer;
    hint = <HintComponent isVisible={isVisible} layerId={layer.id} />;
  } else if (layer.hint) {
    hint = layer.hint;
  }

  return (
    <MenuCheckbox
      actions={
        layer.legend && (
          <LegendToggleButton legend={layer.legend} variant="ghostWeak" />
        )
      }
      checked={isLayerActive(layer.id)}
      data-testid={layer.id}
      defaultIsOpen={state ? state.opacity !== 1 : false}
      disabled={layer.disabled}
      hint={hint}
      label={layer.label}
      onChange={onChange}
      onClose={onClose}
    >
      <SettingsMenuSection layer={layer} />
    </MenuCheckbox>
  );
};

export default LayerCheckbox;
