import { Field, FieldGroup, MenuSection } from "@app/design-system";
import type { LayerConfig } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import type { ActiveLayerState } from "../../util/ActiveLayersProvider/useActiveLayers";
import LayerOpacitySlider from "./LayerOpacitySlider";

interface SettingsMenuSectionProps {
  layer: LayerConfig;
}

const SettingsMenuSection = ({ layer }: SettingsMenuSectionProps) => {
  const { getLayerState, setLayerState } = useActiveLayersContext();

  const state = getLayerState<ActiveLayerState>(layer.id);
  const { settingsComponent: SettingsComponent } = layer;

  return (
    <MenuSection label="Settings">
      <FieldGroup>
        {SettingsComponent && <SettingsComponent />}
        <Field label="Opacity">
          <LayerOpacitySlider
            layerLabel={layer.label}
            onChange={(value) => {
              setLayerState({
                id: layer.id,
                source: "layers-drawer",
                state: { opacity: value / 100 },
              });
            }}
            {...(state && { value: state.opacity * 100 })}
          />
        </Field>
      </FieldGroup>
    </MenuSection>
  );
};

export default SettingsMenuSection;
