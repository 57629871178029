import {
  Checkbox,
  Close,
  IconButton,
  Tune,
  fadeInStyles,
  useTheme,
} from "@app/design-system";
import React, { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const StyledLayerCheckbox = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const StyledRow = styled.div`
  position: relative;
  display: flex;
`;

const StyledControl = styled.div`
  flex: 1;
`;

const StyledClose = styled.div`
  display: flex;
  gap: 0.125rem;
  margin-left: auto;
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;

  ${fadeInStyles}
`;

const StyledSettings = styled.div`
  padding: 0 0 0.5rem calc(0.625rem + 1px);
  ${fadeInStyles}
`;

interface MenuCheckboxProps {
  actions?: React.ReactNode;
  checked?: boolean;
  children?: React.ReactNode;
  defaultIsOpen?: boolean;
  disabled?: boolean;
  hint?: React.ReactNode;
  label?: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClose?: () => void;
  "data-testid"?: string;
}

const MenuCheckbox = ({
  actions,
  checked = false,
  children,
  defaultIsOpen,
  disabled,
  hint,
  label,
  onChange,
  onClose,
  "data-testid": dataTestId,
}: MenuCheckboxProps) => {
  const theme = useTheme();

  const rowRef = useRef<HTMLDivElement>(null);
  const closeRef = useRef<HTMLDivElement>(null);
  const settingsRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  if (!checked && isOpen) {
    setIsOpen(false);
  }

  return (
    <StyledLayerCheckbox>
      <StyledRow ref={rowRef}>
        <StyledControl>
          <Checkbox
            checked={checked}
            disabled={disabled}
            hint={hint}
            label={label}
            onChange={onChange}
            data-testid={dataTestId && `${dataTestId}-checkbox`}
          />
        </StyledControl>
        <CSSTransition
          in={checked}
          mountOnEnter
          nodeRef={closeRef}
          timeout={theme.anim.duration.sm}
          unmountOnExit
        >
          <StyledClose ref={closeRef}>
            {actions}
            <IconButton
              icon={isOpen ? Close : Tune}
              label={isOpen ? "Close" : "Settings"}
              onClick={toggle}
              size="xs"
              variant="ghostWeak"
            />
          </StyledClose>
        </CSSTransition>
      </StyledRow>
      <CSSTransition
        in={isOpen}
        mountOnEnter
        nodeRef={settingsRef}
        onExited={onClose}
        timeout={theme.anim.duration.sm}
        unmountOnExit
      >
        <StyledSettings ref={settingsRef}>{children}</StyledSettings>
      </CSSTransition>
    </StyledLayerCheckbox>
  );
};

export default MenuCheckbox;
